import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Button, Dialog, Grid, Stack } from "@mui/material";

import {
  IconCheersHandsBlueMingle,
  IconCheersHandsMingle,
  IconCommentsMingle,
} from "../brandlibrary/MingleIcons";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import {
  MatchFeedHistoryInterface,
  storage,
  FeedInputEvents,
  MessageTypeEnum,
  IMatchCard,
} from "../../services/api.interfaces";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { CheersButton } from "./CheersButton";
import { useParams, useSearchParams } from "react-router-dom";

interface Props {
  dataMatchFeed: IMatchCard;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});
export const FeedCardFooter: React.FC<Props> = ({ dataMatchFeed }) => {
  const [open, setOpen] = React.useState(false);
  const { cardId } = useParams();
  React.useEffect(() => {
    if (dataMatchFeed.id === cardId) {
      setOpen(true);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      document
        .getElementById(`${dataMatchFeed.id}`)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 500);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Button
        component="div"
        variant="text"
        size="small"
        disableRipple={true}
        sx={{
          color: "#B7BAC6",
          padding: 0,
          "&:hover": {
            background: "transparent",
            textDecoration: "underline",
          },
        }}
        onClick={handleClickOpen}
      >
        <IconCommentsMingle
          sx={{
            color: "#B7BAC6",
            fontSize: "20px",
            marginRight: "4px",
          }}
        />
        {dataMatchFeed?.reactions?.comments}{" "}
        {dataMatchFeed?.reactions?.comments === 1 ? "commment" : "commments"}
      </Button>
    </Stack>
  );
};
