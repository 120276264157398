import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Button, Chip, Grid, Stack } from "@mui/material";
import {
  IconBalMingle,
  IconCheersHandsBlueMingle,
  IconCheersHandsMingle,
  IconCommentsMingle,
} from "../brandlibrary/MingleIcons";
import {
  HomeAwayEnum,
  IMatchCard,
  IMatchStatus,
  MatchFeedHistoryInterface,
  MatchFeedsInterface,
} from "../../services/api.interfaces";

import userImage from "../../images/user.png";
import Typography from "@mui/material/Typography";

interface Props {
  dataMatchFeed: IMatchCard | undefined;
  matchStatusData?: IMatchStatus;
}
export const FeedCardTypeGoal: React.FC<Props> = ({
  dataMatchFeed,
  matchStatusData,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        width: "100%",
      }}
    >
      <Stack
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        sx={{
          width: "100%",
        }}
      >
        <Chip
          label="GOAL"
          color="primary"
          sx={{
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: 700,
            color: theme.palette.secondary.main,
            background: "#E6F0FE",
          }}
        />
        <div>
          <img
            src={matchStatusData?.match?.team?.organization?.logoUrl}
            style={{ width: "50px", height: "auto" }}
          />
        </div>
        <Stack
          direction={
            matchStatusData?.match?.location === HomeAwayEnum.Away
              ? "row-reverse"
              : "row"
          }
          style={{
            fontFamily: "Poppins",
            fontSize: "18px",
            fontWeight: 700,
            color: "#787878",
            background: "#F8F8F8",
            borderRadius: "16px",
            padding: "0 12px",
            lineHeight: "34px",
            maxHeight: "34px",
          }}
        >
          <span
            style={{
              color:
                matchStatusData?.match?.team?.id === dataMatchFeed?.data.teamId
                  ? theme.palette.secondary.main
                  : "#787878",
            }}
          >
            {dataMatchFeed?.data.score?.team
              ? dataMatchFeed?.data.score?.team
              : "0"}
          </span>{" "}
          -{" "}
          <span
            style={{
              color: "#787878",
            }}
          >
            {dataMatchFeed?.data.score?.opponent
              ? dataMatchFeed?.data.score.opponent
              : "0"}
          </span>
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        direction="row"
        justifyContent="flex-start"
        alignContent="center"
        alignItems="center"
        sx={{
          color: "#787878",
          background: "#F8F8F8",
          borderRadius: "8px",
          width: "100%",
          padding: "12px 16px",
        }}
      >
        {matchStatusData?.match?.opponent?.id === dataMatchFeed?.data.teamId ? (
          <img
            src={matchStatusData?.match?.opponent?.organization?.logoUrl}
            style={{ width: "50px", height: "auto" }}
          />
        ) : dataMatchFeed?.data.player ? (
          <img
            src={dataMatchFeed?.data.player?.imageUrl}
            height="32"
            width="32"
            style={{
              border: "1px solid  #878BA0",
              padding: "2px",
              borderRadius: "30px",
            }}
          />
        ) : (
          <img
            src={matchStatusData?.match?.team?.organization?.logoUrl}
            style={{ width: "50px", height: "auto" }}
          />
        )}

        <div>
          <Typography variant="body2" component="span">
            Goal
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 700, color: "#181818" }}>
            {dataMatchFeed?.data.player?.displayName
              ? dataMatchFeed?.data.player?.displayName
              : matchStatusData?.match?.team?.id === dataMatchFeed?.data.teamId
              ? matchStatusData?.match?.team.organization.name
              : matchStatusData?.match?.opponent.fullName}
          </Typography>
        </div>
        <div
          style={{
            marginLeft: "auto",
          }}
        >
          <IconBalMingle
            color="primary"
            sx={{
              marginLeft: "auto",
              opacity: 0.5,
              paddingLeft: "3px",
            }}
          />
        </div>
      </Stack>
      {dataMatchFeed?.data.assistant && (
        <Stack
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignContent="center"
          alignItems="center"
          sx={{
            color: "#787878",
            width: "100%",
            padding: "12px 16px",
          }}
        >
          {dataMatchFeed?.data.assistant ? (
            <img
              src={dataMatchFeed.data.assistant?.imageUrl}
              height="32"
              width="32"
              style={{
                border: "1px solid  #878BA0",
                padding: "2px",
                borderRadius: "30px",
              }}
            />
          ) : (
            <img
              src={matchStatusData?.match?.team?.organization?.logoUrl}
              style={{ width: "50px", height: "auto" }}
            />
          )}

          <div>
            <Typography variant="body2" component="span">
              Assist
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 700, color: "#181818" }}>
              {dataMatchFeed.data.assistant?.displayName
                ? dataMatchFeed.data.assistant?.displayName
                : matchStatusData?.match?.team?.fullName}
            </Typography>
          </div>
        </Stack>
      )}
    </Stack>
  );
};
