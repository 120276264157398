import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme, makeStyles } from "@mui/material/styles";
import { useAuth } from "../hooks/useAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Stack,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  IconButton,
  InputAdornment,
  Slide,
  CardActions,
  Avatar,
  useMediaQuery,
  Theme,
} from "@mui/material";
import gradient from "../images/gradient.png";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import { useState } from "react";
import {
  LogoMingle,
  LogoTextMingle,
} from "../components/brandlibrary/MingleIcons";
import {
  PrivacyEnum,
  IreceivingUser,
  IrequestingUser,
  storage,
  ConnectionStatusEnum,
} from "../services/api.interfaces";
import {
  SignInUpContainer,
  MingleLabel,
  MingleInput,
  LinktItem,
  InFormLinktItem,
} from "../components/SignInUpContainer";

import { appInsights } from "../services/appInsights";
import downloadAppStore from "../images/download-app-store.svg";
import googlePlayBadge from "../images/google-play-badge.png";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
const TeamInvite: React.FC = () => {
  const theme = useTheme();
  let auth = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const { inviteId } = useParams();
  const [isCopied, setIsCopied] = React.useState(false);
  const matchesMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  React.useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);
  React.useEffect(() => {
    appInsights.trackPageView({ name: "teamInvite", pageType: "screen" });
    appInsights.trackEvent(
      { name: "teamInvite" },
      { type: "screen", userId: auth.user.userId }
    );
  }, []);
  return (
    <SignInUpContainer showError={false} showTagline={true}>
      <Stack
        spacing={3}
        direction="column"
        alignSelf="center"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "100%" }}
      >
        <Card
          sx={{
            width: "100%",
            border: "1px solid #EFEFF1",
            borderRadius: "4px",
            boxShadow: "none",
          }}
        >
          <CardContent>
            <Stack
              direction="column"
              spacing={0}
              alignSelf="center"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography
                variant="body2"
                color="#000"
                align="center"
                gutterBottom
                sx={{ marginBottom: "26px" }}
              >
                Download the Mingle Sport app & use the{" "}
                <strong>team code</strong> below to get access to your team!
              </Typography>
              <input
                style={{
                  fontSize: "24px",
                  fontWeight: 700,
                  border: "1px solid #EFEFF1",
                  cursor: "pointer",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "4px",
                }}
                value={inviteId}
                onClick={(e) => {
                  setIsCopied(true);
                  e.currentTarget.select();
                  navigator.clipboard.writeText(inviteId || "");
                }}
              />
              <Typography
                variant="body2"
                color="#ccc"
                align="center"
                gutterBottom
              >
                {isCopied ? <>copied!</> : <>click code to copy!</>}
              </Typography>
            </Stack>
          </CardContent>

          <CardActions
            sx={{
              borderTop: "1px solid #EFEFF1",
              margin: "14px",
              paddingTop: "18px",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignSelf="center"
              alignItems="stretch"
              justifyContent="flex-start"
              sx={{ width: "100%" }}
            >
              <Button
                component="a"
                href="https://mingle.sport/nl/download-instructies/"
                variant="contained"
                fullWidth
                color="secondary"
                size="small"
                target="_blank"
              >
                Download instructies
              </Button>
            </Stack>
          </CardActions>
        </Card>
        <Stack
          spacing={2}
          alignContent="center"
          alignSelf="center"
          alignItems="center"
          // justifyContent="center"
          sx={{
            background: "#fff",
            borderRadius: "32px",
            padding: "30px 70px",
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            component="h3"
            textAlign="center"
            sx={{
              color: "#000",
              fontWeight: "700",
              maxWidth: "750px",
            }}
          >
            Get Mingle Sport
          </Typography>

          <Stack direction={matchesMobile ? "column" : "row"} spacing={2}>
            <a
              href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
              target="_blank"
            >
              <img
                src={downloadAppStore}
                style={{ height: "auto", width: "180px" }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
              target="_blank"
            >
              <img
                src={googlePlayBadge}
                style={{
                  height: matchesMobile ? "auto" : "60px",
                  width: matchesMobile ? "180px" : "auto",
                }}
              />
            </a>
            {isMobile && isIOS && (
              <Button
                variant="outlined"
                color="secondary"
                component="a"
                target="_blank"
                href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
                sx={{
                  margin: "auto",
                  borderWidth: "2px",
                  whiteSpace: "nowrap",
                  "&:hover": { borderWidth: "2px" },
                }}
              >
                Open the app
              </Button>
            )}
            {isMobile && isAndroid && (
              <Button
                variant="outlined"
                color="secondary"
                target="_blank"
                component="a"
                href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
                sx={{
                  margin: "auto",
                  borderWidth: "2px",
                  whiteSpace: "nowrap",
                  "&:hover": { borderWidth: "2px" },
                }}
              >
                Open the app
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </SignInUpContainer>
  );
};
export default TeamInvite;
