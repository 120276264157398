import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../hooks/useAuth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
} from "@mui/material";
import {
  ConnectionStatusEnum,
  IreceivingUser,
  IrequestingUser,
  LocationProps,
  PrivacyEnum,
  storage,
} from "../services/api.interfaces";
import { SignInUpContainer } from "../components/SignInUpContainer";
import { postAcceptFollowInvite, getUserInvite } from "../services/api.calls";

import { appInsights } from "../services/appInsights";

const UserInvite: React.FC = () => {
  const theme = useTheme();
  let auth = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingInvite, setIsLoadingInvite] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState(false);
  const [connectionStatus, setConnectionStatus] =
    React.useState<ConnectionStatusEnum>(ConnectionStatusEnum.inactive);
  const [requestingUser, setrequestingUser] = React.useState<IrequestingUser>({
    firstName: "",
    lastName: "",
    privacy: PrivacyEnum.Private,
    imageUrl: "",
    teams: [],
  });
  const [receivingUser, setreceivingUser] = React.useState<IreceivingUser>({
    firstName: "",
    lastName: "",
    id: "",
    imageUrl: "",
  });

  let inviteId = searchParams.get("encryptedrequestinguser") || undefined;

  const clickAcceptFollowInvite = () => {
    setIsLoading(true);
    postAcceptFollowInvite(inviteId)
      .then(() => {
        getUserInvite(inviteId)
          .then((res) => {
            if (res.connectionStatus === "undefined")
              setConnectionStatus(ConnectionStatusEnum.inactive);
            else {
              setConnectionStatus(res.connectionStatus);
            }
            setIsLoading(false);
          })
          .catch((error: any) => {
            setShowError(true);
          });
      })
      .catch((error: any) => {
        setShowError(true);
        setIsLoading(false);
      });
  };

  const useInviteId = () => {
    setIsLoadingInvite(true);

    getUserInvite(inviteId)
      .then((res) => {
        if (res.connectionStatus === "undefined")
          setConnectionStatus(ConnectionStatusEnum.inactive);
        else {
          setConnectionStatus(res.connectionStatus);
        }

        setrequestingUser(res.requestingUser);
        setreceivingUser(res.receivingUser);
        setIsLoadingInvite(false);
      })
      .catch((error: any) => {
        setShowError(true);
        setIsLoadingInvite(false);
      });
  };
  React.useEffect(() => {
    useInviteId();
  }, [inviteId]);
  React.useEffect(() => {
    appInsights.trackPageView({ name: "userInvite", pageType: "screen" });
    appInsights.trackEvent(
      { name: "userInvite" },
      { type: "screen", userId: auth.user.userId }
    );
  }, []);
  let location = useLocation() as unknown as LocationProps;
  let navigate = useNavigate();

  return (
    <SignInUpContainer showError={showError} showTagline={false}>
      {!isLoadingInvite && !showError && (
        <Stack
          spacing={3}
          direction="column"
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            src={receivingUser.imageUrl}
            sx={{ width: 100, height: 100, margin: "auto" }}
          />

          <Typography
            variant="h2"
            color="#0E0E10"
            align="center"
            gutterBottom
            sx={{ fontSize: "24px", fontWeight: 700 }}
          >
            {receivingUser.firstName} {receivingUser.lastName}
          </Typography>
          <Typography
            variant="h5"
            color="#000"
            align="center"
            sx={{ fontSize: "14px", fontWeight: 700 }}
          >
            Would you like to follow {requestingUser.firstName}{" "}
            {requestingUser.lastName}
          </Typography>
          <Card
            sx={{
              width: "100%",
              maxWidth: "600px",
              minWidth: "340px",
              border: "1px solid #EFEFF1",
              borderRadius: "4px",
              boxShadow: "none",
            }}
          >
            <CardContent>
              <Stack
                direction="row"
                spacing={3}
                alignSelf="center"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Avatar
                  src={requestingUser.imageUrl}
                  sx={{ width: 48, height: 48 }}
                />
                <div>
                  <Typography
                    variant="h5"
                    color="#000"
                    sx={{ fontSize: "16px", fontWeight: 700 }}
                  >
                    {requestingUser.firstName} {requestingUser.lastName}
                  </Typography>
                  {requestingUser.teams.length > 0 && (
                    <>
                      <Typography
                        variant="h5"
                        color="#53585F"
                        sx={{ fontSize: "12px", fontWeight: 500 }}
                      >
                        {requestingUser.teams[0].organization.name}
                      </Typography>
                      <Typography
                        variant="h5"
                        color="#53585F"
                        sx={{ fontSize: "9px", fontWeight: 500 }}
                      >
                        {requestingUser.teams[0].fullName}
                      </Typography>
                    </>
                  )}
                </div>
              </Stack>
            </CardContent>
            {connectionStatus === ConnectionStatusEnum.active && (
              <CardActions
                sx={{
                  borderTop: "1px solid #EFEFF1",

                  paddingTop: "18px",
                  background: "#E7E9FA",
                  borderRadius: "4px",
                }}
              >
                {" "}
                <Typography
                  variant="h4"
                  color="#0B1ECF"
                  align="center"
                  gutterBottom
                  sx={{ fontSize: "16px", fontWeight: 700, width: "100%" }}
                >
                  You are following {requestingUser.firstName}
                </Typography>
              </CardActions>
            )}

            {connectionStatus === ConnectionStatusEnum.inactive && (
              <CardActions
                sx={{
                  borderTop: "1px solid #EFEFF1",
                  margin: "14px",
                  paddingTop: "18px",
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignSelf="center"
                  alignItems="stretch"
                  justifyContent="flex-start"
                  sx={{ width: "100%" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="secondary"
                    sx={{
                      pointerEvents: isLoading ? "none" : "inherit",
                      flex: 1,
                    }}
                    size="small"
                    endIcon={
                      isLoading && (
                        <CircularProgress
                          size={10}
                          sx={{ color: "#68F3CB" }}
                          color="success"
                        />
                      )
                    }
                    onClick={clickAcceptFollowInvite}
                  >
                    Accept
                  </Button>
                </Stack>
              </CardActions>
            )}
            {connectionStatus === ConnectionStatusEnum.pending && (
              <CardActions
                sx={{
                  borderTop: "1px solid #EFEFF1",
                  paddingTop: "18px",
                  background: "#E7E9FA",
                  borderRadius: "4px",
                }}
              >
                {" "}
                <Typography
                  variant="h4"
                  color="#0B1ECF"
                  align="center"
                  gutterBottom
                  sx={{ fontSize: "16px", fontWeight: 700, width: "100%" }}
                >
                  Pending
                </Typography>
              </CardActions>
            )}
          </Card>

          {connectionStatus === ConnectionStatusEnum.active && (
            <>
              <Typography
                variant="h1"
                align="center"
                color="#000"
                sx={{ fontSize: "32px", fontWeight: 700 }}
              >
                Well done
              </Typography>
              <Typography
                variant="body1"
                color="#000"
                align="center"
                sx={{ fontSize: "16px" }}
              >
                Let’s download the Mingle Sport app by following the
                instructions.
              </Typography>

              <Button
                variant="contained"
                fullWidth
                color="secondary"
                href="https://mingle.sport/download-instructions"
              >
                Download app
              </Button>
            </>
          )}
        </Stack>
      )}

      {isLoadingInvite && (
        <>
          <Typography
            variant="h4"
            component="h2"
            sx={{ fontSize: "16px", fontWeight: 700, color: "#000" }}
            align="center"
            gutterBottom
          >
            Checking your invitation id
          </Typography>
          <IconButton aria-label="" edge="end">
            <CircularProgress
              size={25}
              sx={{ color: "#68F3CB" }}
              color="success"
            />
          </IconButton>
        </>
      )}
    </SignInUpContainer>
  );
};
export default UserInvite;
