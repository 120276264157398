import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  IMatchStatus,
  LoginResponseInterface,
  MatchFeedsInterface,
  storage,
} from "../services/api.interfaces";
import { useAuth } from "./useAuth";
import axiosinterceptor from "../services/api.interceptor";
import axios from "axios";

const fetchGetOpenStatus = (matchId: string): Promise<IMatchStatus> => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_WEBAPPI_URL}/MatchFeed/football/matchfeed/status/${matchId}`,
  }).then<IMatchStatus>((res) => res.data);
};
export default function useGetOpenStatus(matchId: string) {
  console.log(
    `${process.env.REACT_APP_BASE_WEBAPPI_URL}/MatchFeed/football/matchfeed/status/${matchId}`
  );
  const queryMatchStatus = useQuery({
    queryKey: ["getMatchStatus"],
    queryFn: () => fetchGetOpenStatus(matchId),
    staleTime: Infinity,
  });

  return {
    ...queryMatchStatus,
    dataMatchStatus: queryMatchStatus.data,
    feedIsLoading: queryMatchStatus.isLoading,
  };
}
