import * as React from "react";
import { useAuth } from "../hooks/useAuth";
import { Navigate, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
const Logout: React.FC = () => {
  let auth = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    auth?.signOut();
    // window.location.href = "/";
  }, []);

  console.log(searchParams.get("from"));
  return (
    <Navigate
      to="/signin"
      state={{
        from: searchParams.get("from") || "/",
      }}
    />
  );
};
export default Logout;
