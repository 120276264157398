import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme, makeStyles } from "@mui/material/styles";
import { useAuth } from "../hooks/useAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as yup from "yup";
import { useFormik } from "formik";
import testFlightImage from "../images/testflight.png";
import googlePlayImage from "../images/googleplay.png";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Stack,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  IconButton,
  InputAdornment,
  Slide,
  CardActions,
  Avatar,
} from "@mui/material";
import gradient from "../images/gradient.png";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import { useState } from "react";
import {
  LogoMingle,
  LogoTextMingle,
} from "../components/brandlibrary/MingleIcons";
import { PrivacyEnum, storage } from "../services/api.interfaces";
import {
  SignInUpContainer,
  MingleLabel,
  MingleInput,
  LinktItem,
  InFormLinktItem,
} from "../components/SignInUpContainer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { postAcceptFollowInvite, getUserInvite } from "../services/api.calls";
import CheckIcon from "@mui/icons-material/Check";
import userImage from "../images/user.png";
import { addDefaultSrc } from "../utils/common";
import {
  FeedCardFooter,
  FeedCardHeader,
  FeedCardType,
} from "../components/feed";
import phoneImage from "../images/phone-top-2x.webp";
const DownloadIntructions: React.FC = () => {
  const theme = useTheme();

  const { os } = useParams();

  return (
    <SignInUpContainer showError={false} showTagline={false}>
      <Stack
        spacing={3}
        direction="column"
        alignSelf="center"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h2"
          align="center"
          color="#000"
          sx={{ fontSize: "28px", fontWeight: 700 }}
        >
          How to download?
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="#000"
          sx={{ fontSize: "16px", fontWeight: 700 }}
        >
          Select which OS you use:
        </Typography>
        <Stack
          direction="row"
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ width: "400px" }}
        >
          <Button
            component={Link}
            variant="outlined"
            fullWidth
            size="small"
            color="secondary"
            to="/download/ios"
            disabled={os === "ios"}
          >
            IOS
          </Button>
          <Button
            component={Link}
            variant="outlined"
            fullWidth
            size="small"
            color="secondary"
            to="/download/android"
            disabled={os === "android"}
          >
            Android
          </Button>
        </Stack>
        {os != "ios" ? (
          <Stack
            spacing={3}
            direction="column"
            alignSelf="center"
            alignItems="center"
            justifyContent="center"
            sx={{ maxWidth: "340px" }}
          >
            <Typography
              variant="h1"
              align="center"
              color="#000"
              sx={{ fontSize: "24px", fontWeight: 700 }}
            >
              Download Mingle Sport
            </Typography>
            <Typography
              variant="body1"
              color="#000"
              align="center"
              sx={{ fontSize: "16px" }}
            >
              Download the Mingle Sport App in the Playstore.
            </Typography>

            <img
              style={{ margin: "30px auto" }}
              width="180"
              src={googlePlayImage}
            />
          </Stack>
        ) : (
          <Stack
            spacing={3}
            direction="column"
            alignSelf="center"
            alignItems="center"
            justifyContent="center"
            sx={{ maxWidth: "340px" }}
          >
            <img
              style={{ margin: "40px auto 10px" }}
              width="64"
              src={testFlightImage}
            />
            <Typography
              variant="h1"
              align="center"
              color="#000"
              sx={{ fontSize: "24px", fontWeight: 700 }}
            >
              Install Mingle Sport via TestFlight
            </Typography>
            <Typography
              variant="body1"
              color="#000"
              align="center"
              sx={{ fontSize: "16px" }}
            >
              Download and install TestFlight on your phone to get early access
              to the Mingle Sport app.
            </Typography>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="secondary"
              onClick={(e) => {}}
            >
              Download TestFlight
            </Button>
          </Stack>
        )}
        <Typography
          variant="body1"
          color="#000"
          align="center"
          sx={{ fontSize: "16px" }}
        >
          If you are experiencing any problems, please contact us in this
          WhatsApp support chat.
        </Typography>
      </Stack>
    </SignInUpContainer>
  );
};
export default DownloadIntructions;
