import * as React from "react";
import { useInView } from "react-intersection-observer";
import {
  IMatchCard,
  MatchFeedHistoryInterface,
} from "../../services/api.interfaces";
import { Player } from "bitmovin-player";
import { InView } from "react-intersection-observer";
import "bitmovin-player/bitmovinplayer-ui.css";
import { useAudio } from "react-use";
import LinearProgress from "@mui/material/LinearProgress";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import { useState } from "react";

interface Props {
  dataMatchFeed: IMatchCard;
}
export const FeedCardTypeAudio: React.FC<Props> = ({ dataMatchFeed }) => {
  const [playedPercentage, setPlayedPercentage] = useState(0);
  const [playedTime, setPlayedTime] = useState("0");
  const [audio, state, controls, ref] = useAudio({
    src: dataMatchFeed?.data?.audioUrl || "",
    autoPlay: true,
  });
  React.useEffect(() => {
    var date = new Date(0);
    date.setSeconds(state.time);
    setPlayedTime(date.toISOString().substring(14, 19));
    setPlayedPercentage(state.time / (state.duration / 100));
  }, [state]);

  return (
    <div style={{ width: "100%", height: "auto" }}>
      {audio}

      <Stack
        direction="row"
        sx={{ color: "#000" }}
        spacing={2}
        alignContent="center"
        alignSelf="center"
        alignItems="center"
        justifyContent="center"
      >
        {state.paused ? (
          <IconButton aria-label="play" onClick={controls.play}>
            <PlayArrowIcon />
          </IconButton>
        ) : (
          <IconButton aria-label="play" onClick={controls.pause}>
            <PauseIcon />
          </IconButton>
        )}
        <Typography variant="body2" sx={{ fontSize: "14px" }}>
          {playedTime}
        </Typography>
        <LinearProgress
          sx={{ width: "100%" }}
          variant="determinate"
          value={playedPercentage}
          color="primary"
        />
        <IconButton
          aria-label="play"
          onClick={() => controls.seek(state.time - 2)}
        >
          <FastRewindIcon />
        </IconButton>
        <IconButton
          aria-label="play"
          onClick={() => controls.seek(state.time + 2)}
        >
          <FastForwardIcon />
        </IconButton>
      </Stack>
    </div>
  );
};
