import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  IMatchCard,
  LoginResponseInterface,
  MatchFeedsInterface,
  storage,
} from "../services/api.interfaces";
import { useAuth } from "./useAuth";
import axiosinterceptor from "../services/api.interceptor";
import axios from "axios";

const fetchGetOpenMatchFeed = (
  matchId: string,
  cardId: string
): Promise<IMatchCard> => {
  const cardIdUrl = cardId ? `/message/${cardId}` : "";
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_WEBAPPI_URL}/MatchFeed/football/matchfeed/${matchId}${cardIdUrl}`,
  }).then<IMatchCard>((res) => res.data);
};
export default function useGetOpenMatchFeed(matchId: string, cardId: string) {
  console.log(
    `${process.env.REACT_APP_BASE_WEBAPPI_URL}/MatchFeed/football/matchfeed/${matchId}/message/${cardId}`
  );
  const queryMatchFeed = useQuery({
    queryKey: ["getOpenMatchFeed"],
    queryFn: () => fetchGetOpenMatchFeed(matchId, cardId),
    staleTime: Infinity,
  });

  return {
    ...queryMatchFeed,
    dataMatchFeed: queryMatchFeed.data,
    feedIsLoading: queryMatchFeed.isLoading,
  };
}
