import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Button, CardMedia, Grid, Stack } from "@mui/material";
import {
  IconCheersHandsBlueMingle,
  IconCheersHandsMingle,
  IconCommentsMingle,
} from "../brandlibrary/MingleIcons";
import {
  IMatchCard,
  MatchFeedHistoryInterface,
} from "../../services/api.interfaces";

import { InView, useInView } from "react-intersection-observer";
import { useState } from "react";
import Typography from "@mui/material/Typography";

interface Props {
  dataMatchFeed: IMatchCard;
}
export const FeedCardTypeImage: React.FC<Props> = ({ dataMatchFeed }) => {
  const theme = useTheme();

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  return (
    <>
      <div
        style={{
          minHeight: "340px",
          width: "auto",
          background: `url(https://mingle.sport/logo-placeholder.png) center center ${theme.palette.background.default} `,
        }}
        ref={ref}
      >
        {inView && (
          <CardMedia
            component="img"
            height="340"
            image={
              dataMatchFeed.data?.imageUrl
                ? dataMatchFeed.data?.imageUrl
                : "https://mingle.sport/logo-placeholder.png"
            }
            alt=""
          />
        )}
      </div>

      <Typography variant="body2" sx={{ marginTop: "4px" }}>
        {dataMatchFeed.data?.description?.text}
      </Typography>
    </>
  );
};
