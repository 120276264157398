import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import {
  LogoMingle,
  LogoTextMingle,
} from "../components/brandlibrary/MingleIcons";
import { useTheme, makeStyles } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import downloadAppStore from "../images/download-app-store.svg";
import googlePlayBadge from "../images/google-play-badge.png";
import { useAuth } from "../hooks/useAuth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { storage } from "../services/api.interfaces";
import { useState } from "react";
import gradient from "../images/gradient.png";
import gradientBottom from "../images/gradient-bottom.png";
import { Theme } from "@mui/material";
import phoneImage from "../images/phone-top-2x.webp";
import { useIntercom } from "react-use-intercom";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import Logout from "./Logout";

import QRCode from "react-qr-code";
const YouNeedTheApp: React.FC = () => {
  const navigate = useNavigate();
  const [matchFeedId, setMatchFeedId] = useState(storage.getMatchId());
  const { boot, shutdown, hide, show, update } = useIntercom();
  boot();
  let apiEnv = process.env.REACT_APP_ENV === "TST" ? "tst" : "prd";
  const matchesMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <div
      style={{
        width: "100%",
        minHeight: matchesMobile ? "100vh" : "100vh",
        background: "#F7F7F8",
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: "transparent",
          height: "80px",
        }}
      >
        <div
          style={{
            backgroundColor: "#68F3CB",
            borderRadius: matchesMobile ? "12px" : "16px",
            height: matchesMobile ? "32px" : "48px",
            width: matchesMobile ? "32px" : "48px",
            padding: matchesMobile ? "6px" : "12px",
            lineHeight: matchesMobile ? "20px" : "24px",
            marginRight: "16px",
            marginLeft: 0,
          }}
        >
          <LogoMingle
            sx={{
              color: "#0B1ECF",
              fontSize: matchesMobile ? "20px" : "24px",
              lineHeight: matchesMobile ? "20px" : "24px",
              textDecoration: "none",
            }}
          />
        </div>
        {!matchesMobile && (
          <LogoTextMingle
            width="181"
            height="29"
            sx={{
              color: "#000",
              height: "29px",
              fontSize: "181px",
              margin: "0 20px 0 0",
              textDecoration: "none",
            }}
          />
        )}
      </Toolbar>

      <Container maxWidth="xl">
        <Stack
          spacing={3}
          alignContent="center"
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: matchesMobile ? "200px" : "calc(100vh - 80px)",
          }}
        >
          <Stack spacing={8} direction="column" sx={{}}>
            <Stack
              spacing={4}
              alignContent="center"
              alignSelf="center"
              alignItems="center"
              // justifyContent="center"
              sx={{
                background: "#fff",
                borderRadius: "32px",
                padding: "30px 70px",
                width: "100%",
                minHeight: matchesMobile ? "100%" : "300px",
              }}
            >
              <Typography
                variant="h3"
                component="h3"
                textAlign="center"
                sx={{
                  color: "#000",
                  fontWeight: "700",
                  maxWidth: "750px",
                }}
              >
                You need the app for this
              </Typography>
              <QRCode value={window.location.href} />

              <Typography
                variant="body1"
                textAlign="center"
                sx={{ color: "#000", maxWidth: "250px" }}
              >
                Scan the QR code to finish what you’ve started in the Mingle
                app.
              </Typography>
            </Stack>
            <Stack
              spacing={3}
              alignContent="center"
              justifyContent="center"
              alignItems="center"
            >
              {isMobile && isIOS && (
                <Button
                  variant="outlined"
                  color="secondary"
                  component="a"
                  target="_blank"
                  href={`${apiEnv === "tst" ? "tst.mingle://" : "mingle://"}${
                    window.location.host +
                    window.location.pathname +
                    window.location.search
                  }`}
                  sx={{
                    margin: "auto",
                    borderWidth: "2px",
                    whiteSpace: "nowrap",
                    "&:hover": { borderWidth: "2px" },
                  }}
                >
                  Open the app
                </Button>
              )}
              <Typography
                variant="h3"
                component="h3"
                textAlign="center"
                sx={{
                  color: "#000",
                  fontWeight: "700",
                  maxWidth: "750px",
                }}
              >
                Don’t have the app?
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  margin: "auto",
                }}
              >
                <a
                  href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
                  target="_blank"
                >
                  <img
                    src={downloadAppStore}
                    style={{ height: "54px", width: "auto" }}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
                  target="_blank"
                >
                  <img
                    src={googlePlayBadge}
                    style={{ height: "54px", width: "auto" }}
                  />
                </a>
              </Stack>
            </Stack>
            <Stack
              spacing={2}
              alignContent="center"
              alignSelf="center"
              alignItems="center"
              // justifyContent="center"
              sx={{
                background: "#fff",
                borderRadius: "32px",
                padding: "30px 70px",
                width: "100%",
              }}
            >
              <Typography
                variant="h3"
                component="h3"
                textAlign="center"
                sx={{
                  color: "#000",
                  fontWeight: "700",
                  maxWidth: "750px",
                }}
              >
                Need help?
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ color: "#000" }}
              >
                Contact support
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={
                  {
                    // borderWidth: "2px",
                    // whiteSpace: "nowrap",
                    // "&:hover": { borderWidth: "2px" },
                  }
                }
                onClick={() => {
                  show();
                }}
              >
                Ask support
              </Button>
            </Stack>
          </Stack>

          <div style={{ textAlign: "center", margin: "40px" }}>
            <Typography
              variant="body1"
              textAlign="center"
              gutterBottom
              sx={{ color: "#000" }}
            >
              For more information, visit{" "}
              <a
                href="https://mingle.sport"
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {" "}
                mingle.sport
              </a>
            </Typography>
          </div>
        </Stack>
      </Container>
    </div>
  );
};
export default YouNeedTheApp;
