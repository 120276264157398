import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme, makeStyles } from "@mui/material/styles";
import { useAuth } from "../hooks/useAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Stack,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import gradient from "../images/gradient.png";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// import useLogin from "../hooks/useLogin";
import { useState } from "react";
import {
  LogoMingle,
  LogoTextMingle,
} from "../components/brandlibrary/MingleIcons";
import {
  LocationProps,
  SignInCredentials,
  storage,
} from "../services/api.interfaces";
import {
  SignInUpContainer,
  MingleLabel,
  MingleInput,
  LinktItem,
  InFormLinktItem,
  MingleTextFieldAdornMent,
} from "../components/SignInUpContainer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { appInsights } from "../services/appInsights";
const SignIn: React.FC = () => {
  const theme = useTheme();
  let auth = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(" E-mailadres klopt niet")
      .required(" E-mailadres is verplicht"),
    password: yup.string().required("Wachtwoord is verplicht"),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: SignInCredentials) => {
      setIsLoading(true);
      setShowError(false);

      auth
        ?.signIn({
          email: values.email,
          password: values.password,
        })
        .then(() => {
          setIsLoading(false);
          if (searchParams.get("matchfeed")) {
            storage.setMatchId(searchParams.get("matchfeed") || "");
          }

          navigate(location.state?.from || "/", {
            replace: true,
          });
        })
        .catch((error: any) => {
          setShowError(true);
          setIsLoading(false);
        });
    },
  });

  let location = useLocation() as unknown as LocationProps;
  let navigate = useNavigate();

  React.useEffect(() => {
    appInsights.trackPageView({ name: "signIn", pageType: "screen" });
    appInsights.trackEvent(
      { name: "signIn" },
      { type: "screen", userId: auth.user.userId }
    );
  }, []);
  return (
    <SignInUpContainer showError={showError}>
      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <Stack spacing={3}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ color: "#000" }}
            gutterBottom
          >
            <strong>Log in</strong>
          </Typography>
          <FormControl variant="outlined">
            <MingleLabel shrink htmlFor="email">
              E-mail
            </MingleLabel>
            <MingleInput
              placeholder="Typ hier"
              name="email"
              type="text"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </FormControl>

          <FormControl variant="outlined">
            <MingleLabel shrink htmlFor="password">
              Password
            </MingleLabel>
            <MingleTextFieldAdornMent
              placeholder="Typ hier"
              name="password"
              id="password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibilty"
                      title="toggle password visibilty"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <span
            style={{ color: "#0B1ECF", fontSize: "14px", marginTop: "10px" }}
          >
            <InFormLinktItem to="https://password.mingle.sport">
              Forgot password?
            </InFormLinktItem>
          </span>
          {/*<MingleInput*/}
          {/*  placeholder="matchfeed"*/}
          {/*  sx={{*/}
          {/*    opacity: searchParams.get("matchfeed") ? 0.4 : 1,*/}
          {/*  }}*/}
          {/*  name="matchFeedId"*/}
          {/*  id="matchFeedId"*/}
          {/*  defaultValue={searchParams.get("matchfeed")}*/}
          {/*/>*/}
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{
              pointerEvents: isLoading ? "none" : "inherit",
            }}
            endIcon={
              isLoading && (
                <IconButton aria-label="" edge="end">
                  <CircularProgress
                    size={15}
                    sx={{ color: "#68F3CB" }}
                    color="success"
                  />
                </IconButton>
              )
            }
          >
            Log in
          </Button>

          <Typography
            variant="h6"
            component="h2"
            sx={{ color: "#000" }}
            align="center"
            gutterBottom
          >
            Not yet registered?{" "}
            <LinktItem
              to="/signup"
              state={{
                from: location.state?.from || "/",
              }}
              onClick={() => {
                if (searchParams.get("matchfeed")) {
                  storage.setMatchId(searchParams.get("matchfeed") || "");
                }
              }}
            >
              Create a Mingle Sport account
            </LinktItem>
          </Typography>
        </Stack>
      </form>
    </SignInUpContainer>
  );
};
export default SignIn;
