import { createTheme, Theme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
const mingleSpacing = [0, 4, 8, 12, 16, 20, 24, 28, 32];
// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#0F1841",
      light: "#2A356B",
    },
    secondary: {
      main: "#0B1ECF",
      light: "#EAF3FF",
      // dark: "",
    },
    error: {
      main: "#E70000",
      light: "#FDE6E6",
      // dark: "",
    },
    warning: {
      main: "#FFDB1D",
      light: "#FFFBE8",
      // dark: "",
    },
    info: {
      main: "#0B1ECF",
      light: "#EAF3FF",
    },
    success: {
      main: "#60EAC9",
      light: "#E0FCF5",
      dark: "#00B670",
    },
    text: {
      primary: "#18276B",
      secondary: "",
      disabled: "",
    },
    background: {
      paper: "#E9EFFD",
      default: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: { fontSize: "2rem", fontWeight: 400 },
    h2: { fontSize: "1.75rem", fontWeight: 400 },
    h3: { fontSize: "1.5rem", fontWeight: 400 },
    h4: { fontSize: "1.25rem", fontWeight: 400 },
    h5: { fontSize: "1.125rem", fontWeight: 400 },
    h6: { fontSize: "1rem", fontWeight: 400 },
    subtitle1: { fontSize: "1.125rem" },
    subtitle2: { fontSize: "1rem" },
    body1: {
      fontFamily: [
        "Poppins",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: "1.125rem",
    },
    body2: {
      fontFamily: [
        "Poppins",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    caption: { fontSize: "1rem" },
    overline: { fontSize: "0.75", textTransform: "uppercase" },
  },
  // spacing: mingleSpacing,
  components: {
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple!
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.4,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // color: "#000",
          // borderRadius: "16px",
          // backgroundColor: "#F7F7F8",
          // border: "none",
          "& input": {
            backgroundColor: "#F7F7F8",
            color: "#0B1ECF",
            border: 0,
            paddingLeft: `${mingleSpacing[6]}px `,
            paddingRight: `${mingleSpacing[6]}px `,
            // fontSize: "1.125rem",
            fontSize: "1rem",
            borderRadius: 40,
            "&:-webkit-autofill": {
              borderRadius: 40,
            },
          },
          "& fieldset": {
            // backgroundColor: "#F7F7F8",
            // color: "#0B1ECF",
            border: 0,
            fontSize: "1.125rem",
            borderRadius: 40,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
          borderRadius: "30px",
          boxShadow: "none",
          fontFamily: [
            "Poppins",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
          fontWeight: 700,
          textTransform: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
        },
        sizeSmall: {
          fontWeight: 500,
          fontSize: ".875rem",
          padding: `${mingleSpacing[2]}px ${mingleSpacing[4]}px `,
        },
        sizeMedium: {
          padding: `${mingleSpacing[4]}px ${mingleSpacing[8]}px `,
        },
        sizeLarge: {
          borderRadius: "40px",
          fontSize: "1.125rem",
          padding: `${mingleSpacing[6]}px ${mingleSpacing[8]}px `,
        },
        containedSecondary: {
          color: "#68F3CB",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid #F3F4F6",
          fontSize: "1rem",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
          background: "#fff",
        },
      },
    },
  },
});

export default theme;
