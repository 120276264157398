import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  IUserProfile,
  LoginResponseInterface,
  MatchFeedsInterface,
  storage,
  TeamStats,
} from "../services/api.interfaces";
import { useAuth } from "./useAuth";
import axiosinterceptor from "../services/api.interceptor";

const fetchGetUserProfile = (userId: string): Promise<IUserProfile> => {
  return axiosinterceptor({
    method: "GET",
    url: `/User/Public/${userId}`,
  }).then<IUserProfile>((res) => res.data);

  //2022-01-01T12:49:08.000Z
  //2022-12-31T12:49:08.000Z
};
export default function useGetUserProfile(userId: string) {
  const queryUserProfile = useQuery({
    queryKey: ["getUserProfile"],
    queryFn: () => fetchGetUserProfile(userId),
    staleTime: Infinity,
  });

  return {
    ...queryUserProfile,
    dataUser: queryUserProfile.data,
    dataUserIsLoading: queryUserProfile.isLoading,
  };
}
