import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Button, Grid, Stack } from "@mui/material";
import {
  IconCheersHandsBlueMingle,
  IconCheersHandsMingle,
  IconCommentsMingle,
} from "../brandlibrary/MingleIcons";
import {
  IMatchCard,
  MatchFeedHistoryInterface,
} from "../../services/api.interfaces";
import { addDefaultSrc } from "../../utils/common";
import userImage from "../../images/user.png";
import Typography from "@mui/material/Typography";

interface Props {
  dataMatchFeed: IMatchCard;
}
export const FeedCardTypeText: React.FC<Props> = ({ dataMatchFeed }) => {
  const theme = useTheme();

  return (
    <Typography variant="body2" sx={{ fontWeight: 600 }} gutterBottom>
      {dataMatchFeed.data.text}
    </Typography>
  );
};
