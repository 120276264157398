import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Brandlibrary from "./pages/Brandlibrary";
import Profile from "./pages/Profile";

import { AuthProvider, RequireAuth } from "./hooks/useAuth";
import BottomBar from "./components/BottomBar";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import GetMingle from "./pages/GetMingle";

import Logout from "./pages/Logout";
import UserInvite from "./pages/UserInvite";
import DownloadIntructions from "./pages/DownloadInstructions";
import TeamInvite from "./pages/TeamInvite";
import Kampong from "./pages/Kampong";
import KampongUserInvite from "./pages/KampongUserInvite";

import OpenMatchFeed from "./pages/OpenMatchFeed";
import UserProfile from "./pages/UserProfile";
import YouNeedTheApp from "./pages/YouNeedTheApp";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<BottomBar />}>
          <Route index element={<GetMingle />} />
          <Route path="*" element={<YouNeedTheApp />} />
          {/*<Route*/}
          {/*  path="/account"*/}
          {/*  element={*/}
          {/*    <RequireAuth>*/}
          {/*      <Profile />*/}
          {/*    </RequireAuth>*/}
          {/*  }*/}
          {/*/>*/}

          <Route path="/matchfeed/:matchfeedId" element={<OpenMatchFeed />} />
          <Route
            path="/matchfeed/:matchfeedId/:cardId"
            element={<OpenMatchFeed />}
          />
          <Route path="/getmingle" element={<GetMingle />} />
          <Route path="/download/:os" element={<DownloadIntructions />} />
          {/*<Route path="/kampong" element={<Kampong />} />*/}
          {/*<Route path="/brand" element={<Brandlibrary />} />*/}
          <Route path="/team/invite/:inviteId" element={<TeamInvite />} />

          {/*<Route path="*" element={<Navigate replace to="/signin" />} />*/}
          <Route path="/logout" element={<Logout />} />
          <Route path="/signin" element={<SignIn />} />
          {/*<Route path="/signup" element={<SignUp />} />*/}

          <Route path="/user/:userId" element={<UserProfile />} />
          {/*<Route*/}
          {/*  path="/user/invite/kampong"*/}
          {/*  element={*/}
          {/*    <RequireAuth>*/}
          {/*      <KampongUserInvite />*/}
          {/*    </RequireAuth>*/}
          {/*  }*/}
          {/*/>*/}
          <Route
            path="/user/follower/invite"
            element={
              <RequireAuth>
                <UserInvite />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
