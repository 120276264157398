import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/material";
import {
  FeedCardTypesEnum,
  IMatchCard,
  IMatchStatus,
  MatchFeedHistoryInterface,
} from "../../services/api.interfaces";
import { FeedCardTypeMatchUpdate } from "./FeedCardTypeMatchUpdate";

import { FeedCardTypeImage } from "./FeedCardTypeImage";
import { FeedCardTypeGoal } from "./FeedCardTypeGoal";
import { FeedCardTypeText } from "./FeedCardTypeText";
import { FeedCardTypeTeamUpdate } from "./FeedCardTypeTeamUpdate";
import useGetMatchFeed from "../../hooks/useGetMatchFeed";
import { FeedCardTypeVideoBitMovin } from "./FeedCardTypeVideoBitMovin";
import { FeedCardTypeAudio } from "./FeedCardTypeAudio";
import { FeedCardTypeVideo } from "./FeedCardTypeVideo";
import { FeedCardTypeVideoJWPlayer } from "./FeedCardTypeVideoJWPlayer";

interface Props {
  dataMatchFeed: IMatchCard;
  matchStatusData?: IMatchStatus;
}
export const FeedCardType: React.FC<Props> = ({
  dataMatchFeed,
  matchStatusData,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignContent="center"
      sx={{
        width: "100%",
        padding: "0 0 20px 0",
      }}
    >
      {dataMatchFeed.type === FeedCardTypesEnum.MATCH_STARTED && (
        <FeedCardTypeMatchUpdate dataMatchFeed={dataMatchFeed} />
      )}
      {dataMatchFeed.type === FeedCardTypesEnum.MATCH_ENDED && (
        <FeedCardTypeMatchUpdate dataMatchFeed={dataMatchFeed} />
      )}
      {dataMatchFeed.type === FeedCardTypesEnum.MATCH_HALFTIME && (
        <FeedCardTypeMatchUpdate dataMatchFeed={dataMatchFeed} />
      )}
      {dataMatchFeed.type === FeedCardTypesEnum.MATCH_SECONDHALF && (
        <FeedCardTypeMatchUpdate dataMatchFeed={dataMatchFeed} />
      )}

      {dataMatchFeed.type === FeedCardTypesEnum.VIDEO && (
        <>
          <FeedCardTypeVideoJWPlayer dataMatchFeed={dataMatchFeed} />
        </>
      )}
      {dataMatchFeed.type === FeedCardTypesEnum.AUDIO && (
        <FeedCardTypeAudio dataMatchFeed={dataMatchFeed} />
      )}
      {dataMatchFeed.type === FeedCardTypesEnum.TEAM && (
        <FeedCardTypeTeamUpdate dataMatchFeed={dataMatchFeed} />
      )}
      {dataMatchFeed.type === FeedCardTypesEnum.IMAGE && (
        <FeedCardTypeImage dataMatchFeed={dataMatchFeed} />
      )}
      {dataMatchFeed.type === FeedCardTypesEnum.GOAL && (
        <FeedCardTypeGoal
          dataMatchFeed={dataMatchFeed}
          matchStatusData={matchStatusData}
        />
      )}
      {dataMatchFeed.type === FeedCardTypesEnum.TEXT && (
        <FeedCardTypeText dataMatchFeed={dataMatchFeed} />
      )}
    </Stack>
  );
};
