import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Button, Grid, Stack } from "@mui/material";
import {
  IconCheersHandsBlueMingle,
  IconCheersHandsMingle,
  IconCommentsMingle,
} from "../brandlibrary/MingleIcons";
import {
  IMatchCard,
  IMatchStatus,
  MatchFeedHistoryInterface,
} from "../../services/api.interfaces";

// import whistle from "../../images/whistle.png";
import whistle from "../../images/whistle-new.png";
import Typography from "@mui/material/Typography";

interface Props {
  dataMatchFeed: IMatchCard;
}
export const FeedCardTypeMatchUpdate: React.FC<Props> = ({ dataMatchFeed }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      alignContent="center"
      sx={{ width: "100%" }}
    >
      <Typography variant="h3" sx={{ fontWeight: 600 }} gutterBottom>
        {dataMatchFeed.type === "MATCH_STARTED" && (
          <>
            The match is on
            <br /> its way. 💯
          </>
        )}
        {dataMatchFeed.type === "MATCH_ENDED" && (
          <>The match is over and done. ✔️</>
        )}
        {dataMatchFeed.type === "MATCH_HALFTIME" && (
          <>Half time. Tea time. ⏱️</>
        )}
        {dataMatchFeed.type === "MATCH_SECONDHALF" && (
          <>New half. New changes 🔥</>
        )}
      </Typography>
      <img src={whistle} style={{ padding: "40px 0" }} />
    </Stack>
  );
};
