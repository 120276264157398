import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme, makeStyles, Theme } from "@mui/material/styles";
import { useAuth } from "../hooks/useAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MuiTextField from "@mui/material/TextField";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Stack,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Slide,
  useMediaQuery,
} from "@mui/material";
import gradient from "../images/gradient.png";
import gradientBottom from "../images/gradient-bottom.png";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// import useLogin from "../hooks/useLogin";
import { useState } from "react";
import {
  LogoMingle,
  LogoTextMingle,
} from "../components/brandlibrary/MingleIcons";
import {
  FeedInputEvents,
  MatchFeedHistoryInterface,
  MessageTypeEnum,
  storage,
} from "../services/api.interfaces";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useIntercom } from "react-use-intercom";
const LinkStyle = {
  color: "#000",
  fontWeight: 700,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
};
export const LinktItem = styled(Link)(({}) => LinkStyle);
const InFormLinkStyle = {
  color: "#0B1ECF",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
};
export const InFormAnchorLinktItem = styled("a")(({}) => InFormLinkStyle);
export const InFormLinktItem = styled(Link)(({}) => InFormLinkStyle);
export const MingleLabel = styled(InputLabel)(({ theme }) => ({
  color: "#000",
  fontSize: "12px",
  marginLeft: "-10px",
  fontWeight: 500,
}));
export const MingleTextFieldAdornMent = styled(MuiTextField)(({ theme }) => ({
  "label + &": {
    marginTop: "14px",
  },
  "& .MuiInputBase-input": {
    color: "#000",
    borderRadius: "16px!important",
    border: "none",
  },

  "& .MuiOutlinedInput-input": {
    color: "#000",
    backgroundColor: "#F7F7F8!important",
    paddingRight: "0px!important",
    marginRight: "0px!important",
    borderTopRightRadius: "0!important",
    borderBottomRightRadius: "0!important",
    background: "#F7F7F8!important",
  },
  "& .MuiOutlinedInput-root": {
    paddingRight: "0px",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    marginBottom: "18px",
  },
  "& .MuiInputAdornment-root": {
    padding: "28px 14px",
    marginLeft: 0,
    backgroundColor: "#F7F7F8!important",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
  },
}));
export const MingleInput = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: "14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    color: "#000",
    borderRadius: "16px!important",
    background: "#F7F7F8!important",
    border: "none",
  },
}));
interface Props {
  children?: React.ReactNode;
  showError: boolean;
  showTagline?: boolean;
  errorMessage?: string;
}
export const SignInUpContainer: React.FC<Props> = ({
  children,
  showError,
  showTagline = true,
  errorMessage = "Er is een fout opgetreden. Probeer het later nog een keer.",
}) => {
  const [showSlideError, setShowSlideError] = React.useState(false);
  const theme = useTheme();
  const { boot, shutdown, hide, show, update } = useIntercom();
  boot();
  React.useEffect(() => {
    if (showError) {
      setShowSlideError(true);
      const timer = setTimeout(() => {
        setShowSlideError(false);
      }, 18000);
      return () => clearTimeout(timer);
    }
  }, [showError]);

  const matchesMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Container
      style={{
        minHeight: "100vh",
        background: `url(${gradient}) top center no-repeat, url(${gradientBottom}) bottom center no-repeat`,
      }}
      maxWidth="xl"
    >
      <div
        style={{
          flexGrow: 1,
          textDecoration: "none",
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
          padding: 20,
        }}
      >
        <div
          style={{
            backgroundColor: "#68F3CB",
            borderRadius: matchesMobile ? "12px" : "16px",
            height: matchesMobile ? "32px" : "48px",
            width: matchesMobile ? "32px" : "48px",
            padding: matchesMobile ? "6px" : "12px",
            lineHeight: matchesMobile ? "20px" : "24px",
            marginRight: "16px",
            marginLeft: 0,
          }}
        >
          <LogoMingle
            sx={{
              color: "#0B1ECF",
              fontSize: matchesMobile ? "20px" : "24px",
              lineHeight: matchesMobile ? "20px" : "24px",
              textDecoration: "none",
            }}
          />
        </div>

        <LogoTextMingle
          width="181"
          height="29"
          sx={{
            color: "#000",
            height: "29px",
            fontSize: "181px",
            margin: "0 20px 0 0",
            textDecoration: "none",
          }}
        />
      </div>
      <Stack
        spacing={3}
        alignContent="center"
        alignSelf="center"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: `${theme.spacing(10)} 0`,
          width: "100%",
          height: `calc(100vh - ${theme.spacing(12)})`,
          margin: "auto",
        }}
      >
        {children}
      </Stack>
      <Slide
        direction="down"
        in={showSlideError}
        mountOnEnter
        unmountOnExit
        timeout={{
          appear: 500,
          enter: 300,
          exit: 500,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          alignContent="flex-start"
          sx={{
            color: "#4B5270",
            background: "#FDE6E6",
            padding: "17px 26px",
            position: "absolute",
            top: "75px",
            borderRadius: "8px",
            width: "calc(100% - 32px)",
            maxWidth: "500px",
            left: matchesMobile ? "16px" : "calc(50% - 250px)",
          }}
        >
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: "#4B5270",
            }}
          >
            {errorMessage}
          </Typography>
          <ErrorOutlineOutlinedIcon
            sx={{ fontSize: "22px", color: "#E70000" }}
          />
        </Stack>
      </Slide>
    </Container>
  );
};
