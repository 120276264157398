import * as React from "react";
import { useEffect } from "react";
import { Chip, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  HomeAwayEnum,
  IMatchStatus,
  MatchFeedHistoryInterface,
  MatchFeedsInterface,
  MatchStatusInterface,
  StatusEnum,
} from "../services/api.interfaces";
import moment from "moment";
import { useStopwatch } from "react-timer-hook";
import { IconEyeMingle } from "./brandlibrary/MingleIcons";

interface Props {
  matchStatusData?: IMatchStatus | undefined;
  isSmall: boolean;
}
export const MatchHeader: React.FC<Props> = ({ matchStatusData, isSmall }) => {
  let matchMinutesPlayed: number = 0;

  let matchData = matchStatusData?.match;

  const [showVS, setShowVS] = React.useState<boolean | undefined>(true);
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: true });

  useEffect(() => {
    if (
      matchData?.status === StatusEnum.Started ||
      matchData?.status === StatusEnum.SecondHalf
    ) {
      const startTime = moment(matchData?.startedAtUtc);

      //check for length of halftime
      let halfTimeTotalSeconds = 0;
      if (matchData?.status === StatusEnum.SecondHalf) {
        const halfTime = moment(matchData?.statusUpdates[1].updatedAtUtc);
        const secondHalf = moment(matchData?.statusUpdates[2].updatedAtUtc);
        halfTimeTotalSeconds = secondHalf.diff(halfTime, "seconds");
      }

      let stopwatchOffset = new Date();
      stopwatchOffset.setSeconds(
        stopwatchOffset.getSeconds() +
          moment().diff(startTime, "seconds") -
          halfTimeTotalSeconds
      );

      reset(stopwatchOffset);
      matchMinutesPlayed = hours * 60;
      matchMinutesPlayed += minutes;
    }
    if (
      matchData?.hasGoals ||
      matchData?.isReported ||
      matchData?.status === StatusEnum.Started ||
      matchData?.status === StatusEnum.Finished
    ) {
      setShowVS(false);
    }
  }, [matchData]);

  return (
    <Stack
      direction={
        matchData?.location === HomeAwayEnum.Away ? "row-reverse" : "row"
      }
      justifyContent="space-between"
      alignContent="center"
      sx={{
        position: "relative",
        maxWidth: "600px",
        width: "100%",
        transition: isSmall ? "padding .3s .3s" : "padding .3s 0s",
        textAlign: "center",
        padding: `${isSmall ? "2px" : "5px"} 0`,
      }}
    >
      <Stack
        spacing={1}
        direction={isSmall ? "row-reverse" : "column"}
        alignContent="center"
        sx={{ margin: "auto 0", width: "33%" }}
      >
        {matchData?.team?.organization?.logoUrl ? (
          <img
            src={matchData?.team?.organization?.logoUrl}
            style={{
              width: "auto",
              maxWidth: "200px",
              margin: "0 auto",
              height: isSmall ? "32px" : "48px",
              transition: isSmall ? "width .3s .3s" : "width .3s 0s",
            }}
          />
        ) : (
          <img
            src={matchData?.team?.logoUrl}
            style={{
              width: "auto",
              // maxWidth: "200px",
              margin: "0 auto",
              height: isSmall ? "32px" : "48px",
              transition: isSmall ? "width .3s .3s" : "width .3s 0s",
            }}
          />
        )}

        {!isSmall ? (
          <Typography
            variant="body2"
            color="primary"
            component="div"
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              opacity: 0.6,
              width: "100%",
              textOverflow: "ellipsis",

              overflow: "hidden",
            }}
          >
            {matchData?.team.organization?.name}
            <br />
            {matchData?.team?.shortName}
          </Typography>
        ) : (
          <Typography
            component="div"
            variant="body2"
            color="primary"
            sx={{ opacity: 0.6, fontSize: "14px" }}
          >
            {matchData?.team?.shortName}
          </Typography>
        )}
      </Stack>
      {matchData?.scheduledAtUtc && (
        <Stack
          spacing={isSmall ? 0 : 1}
          direction="column"
          alignContent="center"
          alignItems="center"
          sx={{ margin: "auto 0", color: "#000" }}
        >
          <Typography
            component="div"
            variant="body2"
            color="primary"
            sx={{
              position: "absolute",
              left: "-1px",
              top: "-1px",
              opacity: 0.6,
              fontSize: "12px",
            }}
          >
            {new Date(matchData?.scheduledAtUtc).toLocaleDateString([], {
              month: "short",
              day: "numeric",
            })}
          </Typography>

          <div style={{ transform: "translateY(-6px)" }}>
            {matchData?.status === StatusEnum.Started && (
              <Chip
                size="small"
                label={minutes + hours * 60}
                color="error"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#FC334F",
                  background: "rgba(252, 0, 0, 0.12)",
                }}
              />
            )}
            {matchData?.status === StatusEnum.Scheduled && (
              <Chip
                size="small"
                label={new Date(matchData?.scheduledAtUtc).toLocaleTimeString(
                  [],
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
                color="info"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#0B1ECF",
                  background: "#E7E9FA",
                }}
              />
            )}
            {matchData?.status === StatusEnum.Finished && (
              <Chip
                size="small"
                label="Finished"
                color="info"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#0B1ECF",
                  background: "#E7E9FA",
                }}
              />
            )}
            {matchData?.status === StatusEnum.HalfTime && (
              <Chip
                size="small"
                label="Half Time"
                color="info"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 700,
                  background: "#CFD1D9",
                }}
              />
            )}
            {matchData?.status === StatusEnum.SecondHalf && (
              <Chip
                size="small"
                label={minutes + hours * 60}
                color="error"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#FC334F",
                  background: "rgba(252, 0, 0, 0.12)",
                }}
              />
            )}
          </div>

          {showVS ? (
            <Typography
              component="div"
              variant="h2"
              color="primary"
              sx={{
                margin: "auto",
                fontWeight: 700,
                whiteSpace: "nowrap",
                fontSize: isSmall ? "20px" : "28px",
              }}
            >
              VS
            </Typography>
          ) : (
            <Typography
              component="div"
              variant="h2"
              color="primary"
              sx={{
                margin: "auto",
                fontWeight: 700,
                whiteSpace: "nowrap",
                fontSize: isSmall ? "20px" : "28px",
              }}
            >
              {matchData?.location === HomeAwayEnum.Away ? (
                <>
                  <span>
                    {matchData?.score?.opponent} - {matchData?.score?.team}
                  </span>
                </>
              ) : (
                <>
                  <span>
                    {matchData?.score?.team} - {matchData?.score?.opponent}
                  </span>
                </>
              )}
            </Typography>
          )}

          <Chip
            size="small"
            label={
              <Stack direction="row" spacing={1}>
                <IconEyeMingle
                  sx={{
                    color:
                      matchData?.status === StatusEnum.Scheduled ||
                      matchData?.status === StatusEnum.Finished
                        ? "#000"
                        : "#FC334F",

                    fontSize: "16px",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#000",
                  }}
                >
                  {matchStatusData?.viewsCount}
                </Typography>
              </Stack>
            }
            color="secondary"
            variant="outlined"
            sx={{
              borderColor: "#F7F7F8",
              maxWidth: "60px",
            }}
          />
        </Stack>
      )}

      <Stack
        spacing={1}
        direction={isSmall ? "row" : "column"}
        alignContent="center"
        sx={{ margin: "auto 0", width: "33%" }}
      >
        {matchData?.opponent?.organization?.logoUrl ? (
          <img
            src={matchData?.opponent?.organization?.logoUrl}
            style={{
              width: "auto",
              margin: "0 auto",
              height: isSmall ? "32px" : "48px",
              transition: isSmall ? "width .3s .3s" : "width .3s 0s",
            }}
          />
        ) : (
          <img
            src={matchData?.opponent?.logoUrl}
            style={{
              width: "auto",
              margin: "0 auto",
              height: isSmall ? "32px" : "48px",
              transition: isSmall ? "width .3s .3s" : "width .3s 0s",
            }}
          />
        )}
        {!isSmall ? (
          <Typography
            variant="body2"
            color="primary"
            component="div"
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              opacity: 0.6,
              width: "100%",
              textOverflow: "ellipsis",

              overflow: "hidden",
            }}
          >
            {matchData?.opponent?.organization?.name}
            <br />
            {matchData?.opponent?.nameAbbreviation}
          </Typography>
        ) : (
          <Typography
            component="div"
            variant="body2"
            color="primary"
            sx={{ opacity: 0.6, fontSize: "14px" }}
          >
            {matchData?.opponent?.organization?.name}
            <br />
            {matchData?.opponent?.nameAbbreviation}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
