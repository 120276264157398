import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Grid,
  Stack,
  Card,
  CardContent,
  Box,
  useScrollTrigger,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { storage } from "../services/api.interfaces";

import { MatchHeader } from "../components/MatchHeader";
import {
  FeedCardFooter,
  FeedCardHeader,
  FeedCardType,
} from "../components/feed/";
import { useIntercom } from "react-use-intercom";
import downloadAppStore from "../images/download-app-store.svg";
import googlePlayBadge from "../images/google-play-badge.png";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import matchfeedBackground from "../images/Matchfeed_posts.webp";
import useGetOpenMatchFeed from "../hooks/useGetOpenMatchFeed";
import useGetOpenStatus from "../hooks/useGetOpenStatus";
import QRCode from "react-qr-code";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
let appUrl =
  process.env.REACT_APP_ENV === "TST"
    ? "http://localhost:8000"
    : "https://mingle.sport";

const OpenMatchFeed: React.FC = () => {
  const { boot, shutdown, hide, show, update } = useIntercom();
  boot();
  let apiEnv = process.env.REACT_APP_ENV === "TST" ? "tst" : "prd";
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const { matchfeedId, cardId } = useParams();

  const { dataMatchStatus } = useGetOpenStatus(
    matchfeedId || storage.getMatchId() || ""
  );
  let dataMatchFeed = null;
  if (cardId) {
    dataMatchFeed = useGetOpenMatchFeed(
      matchfeedId || storage.getMatchId() || "",
      cardId || ""
    ).dataMatchFeed;
  }
  const appBarRef = React.createRef<HTMLDivElement>();
  React.useEffect(() => {
    if (matchfeedId !== "") {
      storage.setMatchId(matchfeedId || "");
    }
  }, [matchfeedId]);
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        marginTop: "64px",
        overflow: "auto",
        paddingBottom: "100px",
        width: "100%",
      }}
    >
      <AppBar
        ref={appBarRef}
        position="fixed"
        open={open}
        color="secondary"
        sx={{
          display: "flex",
          background: theme.palette.background.default,

          boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.24)",
          minHeight: 130,
          padding: "8px",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {dataMatchStatus?.match && (
          <MatchHeader matchStatusData={dataMatchStatus} isSmall={false} />
        )}
      </AppBar>
      <Stack
        spacing={2}
        alignContent="center"
        alignSelf="center"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "relative",
          margin: `85px 0}`,
        }}
      >
        {dataMatchFeed && cardId && (
          <Card
            sx={{
              width: "100%",
              maxWidth: "600px",
              // pointerEvents: "none",
            }}
          >
            <CardContent>
              <FeedCardHeader
                dataMatchFeed={dataMatchFeed}
                matchStatusData={dataMatchStatus}
              />
              <FeedCardType
                dataMatchFeed={dataMatchFeed}
                matchStatusData={dataMatchStatus}
              />
              <FeedCardFooter dataMatchFeed={dataMatchFeed} />
            </CardContent>
          </Card>
        )}
        <div
          style={{
            position: "relative",
            width: "100%",
            textAlign: "center",
          }}
        >
          <img
            src={matchfeedBackground}
            style={{ width: "100%", maxWidth: "600px", margin: "auto" }}
          />

          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              background: "rgba(255,255,255, .6)",
              backdropFilter: "blur(10px)",
              // @ts-ignore
              "-webkit-backdrop-filter": "blur(10px)",
              zIndex: 10,
              top: "0",
            }}
          ></div>

          <Stack
            spacing={2}
            alignContent="center"
            alignSelf="center"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: "0",
              width: "100%",
              height: "60%",
              textAlign: "center",
              zIndex: 100,
              margin: "auto",
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              textAlign="center"
              sx={{
                color: "#000",
                fontWeight: "700",
                maxWidth: "750px",
              }}
            >
              You need the app for this
            </Typography>
            <QRCode value={window.location.href} />
            {dataMatchStatus?.match ? (
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ color: "#000", padding: "0px 20px", maxWidth: "400px" }}
              >
                Download the Mingle Sport app, scan the qr-code or use{" "}
                <a
                  style={{
                    fontWeight: "bold",
                    color: "#0B1ECF",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  href={`${apiEnv === "tst" ? "tst.mingle://" : "mingle://"}${
                    window.location.host +
                    window.location.pathname +
                    window.location.search
                  }`}
                >
                  this match link
                </a>{" "}
                to see everything.
              </Typography>
            ) : (
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ color: "#000", padding: "0px 20px", maxWidth: "600px" }}
              >
                Unfortunately, no match feed was found, download the app and
                please try again at a later time.
              </Typography>
            )}
            {isMobile && (
              <Button
                variant="contained"
                color="secondary"
                component="a"
                href={
                  isIOS
                    ? "https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
                    : "https://play.google.com/store/apps/details?id=sport.mingle.android.app"
                }
                sx={{
                  margin: "auto",
                  borderWidth: "2px",
                  whiteSpace: "nowrap",
                  "&:hover": { borderWidth: "2px" },
                }}
              >
                Download app
              </Button>
            )}
            {!isMobile && (
              <div>
                <a
                  href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
                  target="_blank"
                >
                  <img
                    src={downloadAppStore}
                    style={{ height: "auto", width: "180px" }}
                  />
                </a>
                <br />
                <a
                  href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
                  target="_blank"
                >
                  <img
                    src={googlePlayBadge}
                    style={{ height: "auto", width: "180px" }}
                  />
                </a>
              </div>
            )}
          </Stack>
        </div>
      </Stack>
    </Box>
  );
};
export default OpenMatchFeed;
