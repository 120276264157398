import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { InView } from "react-intersection-observer";
// import ReactPlayer from "react-player";
import {
  IMatchCard,
  MatchFeedHistoryInterface,
} from "../../services/api.interfaces";
// @ts-ignore
import JWPlayer from "@jwplayer/jwplayer-react";
import Typography from "@mui/material/Typography";
interface Props {
  dataMatchFeed: IMatchCard;
}
export const FeedCardTypeVideoJWPlayer: React.FC<Props> = ({
  dataMatchFeed,
}) => {
  const theme = useTheme();
  const [videoUrl, setVideoUrl] = React.useState<string | undefined>("");

  const loadPlayer = (inView: boolean) => {
    setVideoUrl(dataMatchFeed.data?.videoUrl);
  };
  return (
    <InView onChange={(inView, entry) => loadPlayer(inView)}>
      <JWPlayer
        file={dataMatchFeed.data?.videoUrl}
        library="https://cdn.jwplayer.com/libraries/wyHeWRqG.js"
        image={
          dataMatchFeed.data?.thumbnailUrl
            ? dataMatchFeed.data?.thumbnailUrl
            : "https://mingle.sport/logo-placeholder.png"
        }
      />
      <Typography variant="body2" sx={{ marginTop: "4px" }}>
        {dataMatchFeed.data?.description?.text}
      </Typography>
    </InView>
  );
};
