import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/material";

import {
  IMatchCard,
  IMatchStatus,
  MatchFeedHistoryInterface,
  MatchFeedsInterface,
  MatchStatusInterface,
  StatusEnum,
} from "../../services/api.interfaces";
import { addDefaultSrc } from "../../utils/common";
import userImage from "../../images/user.png";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { bool } from "yup";
import { useEffect } from "react";

interface Props {
  dataMatchFeed?: IMatchCard | undefined;
  matchStatusData?: IMatchStatus;
}
export const FeedCardHeader: React.FC<Props> = ({
  matchStatusData,
  dataMatchFeed,
}) => {
  const theme = useTheme();
  const [isCardAfterFinish, setIsCardAfterFinish] =
    React.useState<boolean>(false);
  const [isCardBeforeStart, setIsCardBeforeStart] =
    React.useState<boolean>(false);

  const [timeOfCard, setTimeOfCard] = React.useState<number>(0);

  useEffect(() => {
    setTimeOfCard(() => {
      let timeofCurrentCard = 0;
      let halfTimeTotalMinutes = 0;
      if (
        matchStatusData?.match.status === StatusEnum.Finished ||
        matchStatusData?.match.status === StatusEnum.SecondHalf
      ) {
        if (
          moment(dataMatchFeed?.sentAtUtc).diff(
            moment(matchStatusData?.match.statusUpdates[2]?.updatedAtUtc),
            "minutes"
          ) >= 0
        ) {
          const halfTime = moment(
            matchStatusData?.match.statusUpdates[1]?.updatedAtUtc
          );
          const secondHalf = moment(
            matchStatusData?.match.statusUpdates[2]?.updatedAtUtc
          );
          halfTimeTotalMinutes = secondHalf.diff(halfTime, "minutes");
        }
      }

      timeofCurrentCard =
        moment(dataMatchFeed?.sentAtUtc).diff(
          moment(matchStatusData?.match.startedAtUtc),
          "minutes"
        ) - halfTimeTotalMinutes;

      return timeofCurrentCard;
    });

    setIsCardBeforeStart(() => {
      // console.log(
      //   moment(dataMatchFeed.sentAtUtc).diff(
      //     moment(updatedMatchData?.startedAtUtc),
      //     "seconds"
      //   ) < 0
      // );
      if (matchStatusData?.match.startedAtUtc) {
        return (
          moment(dataMatchFeed?.sentAtUtc).diff(
            moment(matchStatusData?.match.startedAtUtc),
            "seconds"
          ) < 0
        );
      }
      return true;
    });
    setIsCardAfterFinish(() => {
      if (matchStatusData?.match.endedAtUtc) {
        return (
          moment(dataMatchFeed?.sentAtUtc) >
          moment(matchStatusData?.match.endedAtUtc)
        );
      }
      return false;
    });
  }, [dataMatchFeed, dataMatchFeed]);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      sx={{
        width: "100%",
        textAlign: "center",
        padding: "0 0 10px",
      }}
    >
      {dataMatchFeed && (
        <Typography variant="body2" color="#878BA0">
          {dataMatchFeed?.type === "MATCH_ENDED" ||
          dataMatchFeed?.type === "MATCH_HALFTIME" ||
          isCardBeforeStart ||
          isCardAfterFinish ? (
            <>
              {new Date(dataMatchFeed?.sentAtUtc).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </>
          ) : (
            <>{timeOfCard}'</>
          )}
        </Typography>
      )}
    </Stack>
  );
};
