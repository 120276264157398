import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import {
  LogoMingle,
  LogoTextMingle,
} from "../components/brandlibrary/MingleIcons";
import { useTheme, makeStyles } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import downloadAppStore from "../images/download-app-store.svg";
import googlePlayBadge from "../images/google-play-badge.png";
import { useAuth } from "../hooks/useAuth";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { storage } from "../services/api.interfaces";
import { useEffect, useState } from "react";
import gradient from "../images/gradient.png";
import gradientBottom from "../images/gradient-bottom.png";
import { Theme } from "@mui/material";
import phoneImage from "../images/phone-top-2x.webp";
import { useIntercom } from "react-use-intercom";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import Logout from "./Logout";
import useGetOpenMatchFeed from "../hooks/useGetOpenMatchFeed";
import useGetUserProfile from "../hooks/useGetUserProfile";
import backgroundCard from "../images/profile-card-border.png";
import { MixPanel } from "../services/mixPanel";
import QRCode from "react-qr-code";
const UserProfile: React.FC = () => {
  const navigate = useNavigate();

  const { boot, shutdown, hide, show, update } = useIntercom();
  boot();
  const { userId } = useParams();
  const { dataUser } = useGetUserProfile(userId || "");
  let apiEnv = process.env.REACT_APP_ENV === "TST" ? "tst" : "prd";
  const matchesMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  useEffect(() => {
    MixPanel.track("shareProfile_followUser_view_page_followUser");
  }, []);
  return (
    <div
      style={{
        width: "100%",
        minHeight: matchesMobile ? "100vh" : "100vh",
        background: "#F7F7F8",
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: "transparent",
          height: "80px",
        }}
      >
        <div
          style={{
            backgroundColor: "#68F3CB",
            borderRadius: matchesMobile ? "12px" : "16px",
            height: matchesMobile ? "32px" : "48px",
            width: matchesMobile ? "32px" : "48px",
            padding: matchesMobile ? "6px" : "12px",
            lineHeight: matchesMobile ? "20px" : "24px",
            marginRight: "16px",
            marginLeft: 0,
          }}
        >
          <LogoMingle
            sx={{
              color: "#0B1ECF",
              fontSize: matchesMobile ? "20px" : "24px",
              lineHeight: matchesMobile ? "20px" : "24px",
              textDecoration: "none",
            }}
          />
        </div>
        {!matchesMobile && (
          <LogoTextMingle
            width="181"
            height="29"
            sx={{
              color: "#000",
              height: "29px",
              fontSize: "181px",
              margin: "0 20px 0 0",
              textDecoration: "none",
            }}
          />
        )}
      </Toolbar>

      <Container maxWidth="xl">
        <Stack
          spacing={3}
          alignContent="center"
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: matchesMobile ? "200px" : "calc(100vh - 80px)",
          }}
        >
          <Stack
            spacing={2}
            alignContent="center"
            alignSelf="center"
            alignItems="center"
            // justifyContent="center"
            sx={{
              background: "#fff",
              borderRadius: "32px",
              padding: matchesMobile ? "22px" : "30px 70px",
              width: "100%",
              maxWidth: "750px",
              minHeight: matchesMobile ? "100%" : "300px",
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              textAlign="center"
              sx={{
                color: "#000",
                fontWeight: "700",
                maxWidth: "750px",
                fontSize: matchesMobile ? "32px" : "52px",
                marginBottom: "20px",
              }}
            >
              Want to follow {dataUser?.profile.firstName}?
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                height: "300px",
                width: "214px",
                marginBottom: "30px",
              }}
            >
              <img
                src={dataUser?.profile.imageUrl}
                style={{
                  width: "214px",
                  height: "258px",
                  position: "absolute",
                  top: "6px",
                  objectFit: "cover",
                }}
              />{" "}
              <img
                src={backgroundCard}
                style={{
                  position: "absolute",
                  maxWidth: "214px",
                  maxHeight: "300px",
                  objectFit: "cover",
                }}
              />
              <Typography
                variant="h3"
                component="h3"
                textAlign="center"
                sx={{
                  fontSize: "22px",
                  color: "#fff",
                  fontWeight: "700",
                  left: "0",
                  width: "100%",
                  maxWidth: "214px",
                  position: "absolute",
                  top: "200px",
                }}
              >
                {dataUser?.profile.firstName}
              </Typography>
              <Typography
                variant="h4"
                component="h4"
                textAlign="center"
                sx={{
                  position: "absolute",
                  fontSize: "12px",
                  left: "0",
                  width: "100%",
                  color: "#fff",
                  fontWeight: "700",
                  maxWidth: "214px",
                  top: "225px",
                }}
              >
                {dataUser?.profile.lastName}
              </Typography>
            </div>
            <Typography
              variant="h3"
              component="h3"
              textAlign="center"
              sx={{
                color: "#000",
                fontWeight: "700",
                maxWidth: "750px",
              }}
            >
              You need the app for this
            </Typography>
            <QRCode value={window.location.href} />
            <Typography
              variant="body1"
              textAlign="center"
              sx={{ color: "#000", padding: "0px 20px", maxWidth: "400px" }}
            >
              Download the Mingle Sport app, scan the qr-code or use{" "}
              <a
                style={{
                  fontWeight: "bold",
                  color: "#0B1ECF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                href={`${apiEnv === "tst" ? "tst.mingle://" : "mingle://"}${
                  window.location.host +
                  window.location.pathname +
                  window.location.search
                }`}
              >
                this user profile link
              </a>{" "}
              to follow {dataUser?.profile.firstName}.
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              textAlign="center"
              sx={{
                color: "#000",
                fontWeight: "700",
                maxWidth: "750px",
              }}
            >
              Download the Mingle Sport App
            </Typography>
            <Stack direction={matchesMobile ? "column" : "row"} spacing={1}>
              <a
                href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
                target="_blank"
                onClick={() => {
                  MixPanel.track(
                    "shareProfile_followUser_tap_button_downloadIos"
                  );
                }}
              >
                <img
                  src={downloadAppStore}
                  style={{
                    height: matchesMobile ? "auto" : "56px",
                    width: matchesMobile ? "160px " : "auto",
                  }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
                target="_blank"
                onClick={() => {
                  MixPanel.track(
                    "shareProfile_followUser_tap_button_downloadAndroid"
                  );
                }}
              >
                <img
                  src={googlePlayBadge}
                  style={{
                    height: matchesMobile ? "auto" : "56px",
                    width: matchesMobile ? "160px " : "auto",
                  }}
                />
              </a>
            </Stack>
            {isMobile && (
              <Typography
                variant="body1"
                textAlign="center"
                sx={{
                  color: "#000",
                  fontWeight: "400",
                  maxWidth: "750px",
                  marginTop: "20px",
                }}
              >
                Do you have the app installed already?
              </Typography>
            )}
            {isMobile && isIOS && (
              <Button
                variant="outlined"
                color="secondary"
                component="a"
                target="_blank"
                href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
                sx={{
                  margin: "auto",
                  borderWidth: "2px",
                  whiteSpace: "nowrap",
                  "&:hover": { borderWidth: "2px" },
                }}
                onClick={() => {
                  MixPanel.track("shareProfile_followUser_tap_button_openApp");
                }}
              >
                Open the app
              </Button>
            )}
            {isMobile && isAndroid && (
              <Button
                variant="outlined"
                color="secondary"
                target="_blank"
                component="a"
                href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
                sx={{
                  margin: "auto",
                  borderWidth: "2px",
                  whiteSpace: "nowrap",
                  "&:hover": { borderWidth: "2px" },
                }}
                onClick={() => {
                  MixPanel.track("shareProfile_followUser_tap_button_openApp");
                }}
              >
                Open the app
              </Button>
            )}
          </Stack>

          {/*<div style={{ textAlign: "center", margin: "40px" }}>*/}
          {/*  <Typography*/}
          {/*    variant="body1"*/}
          {/*    textAlign="center"*/}
          {/*    gutterBottom*/}
          {/*    sx={{ color: "#000" }}*/}
          {/*  >*/}
          {/*    For more information, visit our website*/}
          {/*  </Typography>*/}
          {/*  <Button*/}
          {/*    variant="contained"*/}
          {/*    color="secondary"*/}
          {/*    component="a"*/}
          {/*    href="https://mingle.sport"*/}
          {/*    sx={{*/}
          {/*      margin: "auto",*/}
          {/*      borderWidth: "2px",*/}
          {/*      whiteSpace: "nowrap",*/}
          {/*      "&:hover": { borderWidth: "2px" },*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Go to Mingle.sport*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </Stack>
      </Container>
    </div>
  );
};
export default UserProfile;
