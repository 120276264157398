import * as React from "react";

import { useTheme } from "@mui/material/styles";
import { Button, CardMedia, Grid, Stack } from "@mui/material";
import {
  IconCheersHandsBlueMingle,
  IconCheersHandsMingle,
  IconCommentsMingle,
} from "../brandlibrary/MingleIcons";
import {
  IMatchCard,
  MatchFeedHistoryInterface,
  PlayersInterface,
} from "../../services/api.interfaces";
import { addDefaultSrc } from "../../utils/common";
import userImage from "../../images/user.png";
import Typography from "@mui/material/Typography";
import { InView, useInView } from "react-intersection-observer";
import { useState } from "react";

interface Props {
  dataMatchFeed: IMatchCard;
}
export const FeedCardTypeTeamUpdate: React.FC<Props> = ({ dataMatchFeed }) => {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={3}>
        {dataMatchFeed.data?.players?.map((row: PlayersInterface) => (
          <Grid
            item
            key={row.id}
            sx={{
              width: "50%",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyItems="center"
              alignItems="center"
            >
              <img
                height="32"
                width="32"
                src={row.imageUrl ? row.imageUrl : userImage}
                onError={addDefaultSrc}
                alt={row.firstName}
                style={{
                  border: "1px solid  #878BA0",
                  padding: "2px",
                  borderRadius: "30px",
                }}
              />

              <Typography
                variant="body2"
                sx={{ fontWeight: 600, padding: 0, margin: 0 }}
              >
                {row.firstName} {row.lastName}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
